import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography'

import tab from '../data_gif.json'

const cache = {}
function importAll(r) {
  r.keys().forEach(key => cache[key] = r(key))
}
importAll(require.context('../gif', true, /\.(gif)$/))

class Gif extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.gif_list(classes)}
      </div>
    );
  }

  gif_list = (classes) => {
    return Object.values(tab).map(el =>
      <div key={el.path}>
        <p>{el.date}</p>
        <Typography gutterBottom variant="h5" component="h2">
          {el.description}
        </Typography>
        <img src={cache[el.path].default} alt={el.path} />
        <div className={classes.space_between}></div>
      </div>
    ).sort((a, b) => a - b)
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  space_between: {
    height: 50
  }
});

const styledGif = withStyles(styles)(Gif);
export { styledGif as Gif };
