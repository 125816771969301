import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { cube_game } from '../prog/cube/sketch';

class Cube extends Component {

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div id="game" className={classes.root}>
          <div className={classes.info}>
            <p>
              press F5 if the cube looks ugly<br /><br />
              enter: fullscreen<br />
              q: turn left<br />
              d: turn right<br />
              s: turn down<br />
              z: turn up<br />
              r: zoom_in / zoom_out<br />
              a: next configuration<br />
              e: previous configuration<br />
              f: move piece<br />
            </p>
          </div>
          {cube_game()}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: 650
    },
    info: {
        position: 'absolute',
        left: 0,
        paddingLeft: 20,
        paddingRight: 20,
    }
});

const styledCube = withStyles(styles)(Cube);
export { styledCube as Cube };