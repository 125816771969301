import React from "react";
import Sketch from "react-p5";
import path from './graph.json';

// camera
let angle = 0 // rotation angle of cubes
let rot_speed = 8 // le multiple par lequel on multiplie angle
let cam_rot_speed = 4 // rotation speed of camera
let cam_dist = 400 // rayon entre point d'origin et camera
let zoom = false
let cam_angle_x = 90
let cam_angle_y = 0
let cam_up_y = 1
// animation
let animation // cube's transition animation
let animation_list = [0, 1, 2, 3, 4, 5]
let anim // index of animation_list
let configuration = 0
let manip = true // do not manipulate cube while animation
let cs = 30 // cube_size
let hcs = cs / 2 // half_cube_size
let pos_y = 0 // global position of the entire objet in the space
let cubes = []
let reverse = false
// environment
let planets = []
// color
let yellow
let yellow_2
let orange
let green
let green_2
let blue
let dark_purple
let dark_purple_2
let dark_orange
let dark_blue
let dark_red
let dark_green
let bg // color for background and ambient light
let red
// path
let shape_id = 1
let last_shape = "2a"
let current_shape = "1"
let new_shape
let path_enable
let model_is
// let model_name // current_path of the current_shape
let shape_model_name
let shapes
// piece
let piece
let piece_anim = false
let piece_anim_speed = 2
let piece_pos_x = 0
let piece_pos_y = 0
let is_input = false // does the piece enter or exit the shape
let come_from // need to know where does the piece come from when it enter into a new shape
let start_point
let start_angle
let agl_rot_piece
let agl_rot_piece_speed = 6
// start
let launching = true // is it the first time we launch the program


export const cube_game = () => {
    const setup = p => {
        p.setAttributes('antialias', true)
        p.angleMode(p.DEGREES)
        p.createCanvas(600, 600, p.WEBGL).parent('game');

        yellow = p.color(255, 200, 20)
        yellow_2 = p.color(245, 230, 0)
        orange = p.color(255, 150, 70)
        green = p.color(120, 255, 100)
        green_2 = p.color(180, 215, 80)
        blue = p.color(100, 120, 255)
        dark_purple = p.color(150, 100, 150)
        dark_purple_2 = p.color(170, 110, 120)
        dark_orange = p.color(180, 140, 80)
        dark_blue = p.color(100, 100, 200)
        dark_red = p.color(200, 100, 100)
        dark_green = p.color(100, 200, 100)
        bg = p.color(255, 255, 150)
        red = p.color(255, 20, 20)

        piece = new Piece(p)

        for (let i = -.5; i < 1.5; i++) {
            for (let j = -.5; j < 1.5; j++) {
                for (let k = -.5; k < 1.5; k++) {
                    cubes.push(new Cube(p, 0, 0, 0))
                }
            }
        }
        for (let i = 0; i < 20; i++) {
            planets.push(new Planet(p))
        }
        // cube 0
        let shape_1 = new Shape(p, 1, "model_1", model_1, 90, 0, -90, yellow)
        let shape_2 = new Shape(p, 2, "model_2", model_2, 0, 90, 0, dark_orange)
        let shape_3 = new Shape(p, 3, "model_8", model_8, 90, 0, 0, dark_red)
        let shape_4 = new Shape(p, 4, "model_4", model_4, 0, -90, 90, blue)
        let shape_5 = new Shape(p, 5, "model_4", model_4, 0, 180, 0, green)
        let shape_6 = new Shape(p, 6, "model_4", model_4, 0, 0, 0, dark_purple)
        // cube 3
        let shape_7 = new Shape(p, 7, "model_4", model_4, 90, 0, 0, yellow)
        let shape_8 = new Shape(p, 8, "model_1", model_1, 0, 90, -90, orange)
        let shape_9 = new Shape(p, 9, "model_2", model_2, -90, 0, 90, dark_red)
        let shape_10 = new Shape(p, 10, "model_2", model_2, 0, -90, 0, dark_orange)
        let shape_11 = new Shape(p, 11, "model_4", model_4, 0, 180, 180, green)
        let shape_12 = new Shape(p, 12, "model_5", model_5, 0, 0, 180, dark_purple_2)
        // cube 7
        let shape_13 = new Shape(p, 13, "model_1", model_1, 90, 0, -90, yellow)
        let shape_14 = new Shape(p, 14, "model_5", model_5, 0, 90, 180, dark_orange)
        let shape_15 = new Shape(p, 15, "model_2", model_2, 90, 180, 0, dark_blue)
        let shape_16 = new Shape(p, 16, "model_3", model_3, 0, 90, 90, blue)
        let shape_17 = new Shape(p, 17, "model_4", model_4, 0, 180, 0, dark_purple)
        let shape_18 = new Shape(p, 18, "model_4", model_4, 0, 0, 0, green_2)
        // cube 4
        let shape_19 = new Shape(p, 19, "model_4", model_4, 90, 0, 180, yellow)
        let shape_20 = new Shape(p, 20, "model_4", model_4, 0, 90, 90, orange)
        let shape_21 = new Shape(p, 21, "model_4", model_4, -90, 0, -90, dark_blue)
        let shape_22 = new Shape(p, 22, "model_5", model_5, 0, -90, 90, dark_orange)
        let shape_23 = new Shape(p, 23, "model_3", model_3, 0, 0, 0, dark_purple_2)
        let shape_24 = new Shape(p, 24, "model_4", model_4, 0, 0, 180, green_2)
        // cube 1
        let shape_25 = new Shape(p, 25, "model_3", model_3, 90, 0, 0, dark_red)
        let shape_26 = new Shape(p, 26, "model_4", model_4, 0, 90, 0, dark_green)
        let shape_27 = new Shape(p, 27, "model_4", model_4, -90, 0, -90, yellow_2)
        let shape_28 = new Shape(p, 28, "model_4", model_4, 0, -90, 180, blue)
        let shape_29 = new Shape(p, 29, "model_5", model_5, 0, 180, 90, green)
        let shape_30 = new Shape(p, 30, "model_3", model_3, 0, 0, 0, dark_purple)
        // cube 2
        let shape_31 = new Shape(p, 31, "model_4", model_4, 90, 0, -90, dark_red)
        let shape_32 = new Shape(p, 32, "model_3", model_3, 0, 90, 90, orange)
        let shape_33 = new Shape(p, 33, "model_4", model_4, -90, 0, -90, yellow_2)
        let shape_34 = new Shape(p, 34, "model_4", model_4, 0, -90, 90, dark_green)
        let shape_35 = new Shape(p, 35, "model_4", model_4, 0, 180, -90, green)
        let shape_36 = new Shape(p, 36, "model_4", model_4, 0, 0, 0, dark_purple_2)
        // cube 6
        let shape_37 = new Shape(p, 37, "model_3", model_3, 90, 0, 90, dark_blue)
        let shape_38 = new Shape(p, 38, "model_5", model_5, 180, 90, 0, dark_green)
        let shape_39 = new Shape(p, 39, "model_7", model_7, 90, 180, 0, yellow_2)
        let shape_40 = new Shape(p, 40, "model_4", model_4, 0, -90, 0, blue)
        let shape_41 = new Shape(p, 41, "model_6", model_6, 0, 180, 90, dark_purple)
        let shape_42 = new Shape(p, 42, "model_3", model_3, 0, 0, 0, green_2)
        // cube 5
        let shape_43 = new Shape(p, 43, "model_4", model_4, 90, 0, -90, dark_blue)
        let shape_44 = new Shape(p, 44, "model_5", model_5, 0, 90, -90, orange)
        let shape_45 = new Shape(p, 45, "model_4", model_4, -90, 0, -90, yellow_2)
        let shape_46 = new Shape(p, 46, "model_5", model_5, 0, -90, 180, dark_green)
        let shape_47 = new Shape(p, 47, "model_6", model_6, 0, 180, -90, dark_purple_2)
        let shape_48 = new Shape(p, 48, "model_1", model_1, 0, 0, 0, green_2)

        cubes[0].set_trans(-cs, 0, -hcs)
        cubes[0].set_pos(hcs, -hcs, 0)
        cubes[0].set_shape(shape_2, shape_6, shape_4, shape_5, shape_1, shape_3)

        cubes[1].set_trans(-hcs, hcs, 0)
        cubes[1].set_pos(hcs, hcs, 0)
        cubes[1].set_shape(shape_26, shape_30, shape_28, shape_29, shape_25, shape_27)

        cubes[2].set_trans(hcs, 0, -hcs)
        cubes[2].set_pos(hcs, 0, hcs)
        cubes[2].set_shape(shape_32, shape_36, shape_34, shape_35, shape_31, shape_33)

        cubes[3].set_trans(hcs, -hcs, 0)
        cubes[3].set_pos(-hcs, -hcs, 0)
        cubes[3].set_shape(shape_8, shape_12, shape_10, shape_11, shape_7, shape_9)

        cubes[4].set_trans(0, -hcs, hcs)
        cubes[4].set_pos(0, hcs, hcs)
        cubes[4].set_shape(shape_20, shape_24, shape_22, shape_23, shape_19, shape_21)

        cubes[5].set_trans(hcs, hcs, 0)
        cubes[5].set_pos(-hcs, hcs, 0)
        cubes[5].set_shape(shape_44, shape_48, shape_46, shape_47, shape_43, shape_45)

        cubes[6].set_trans(-hcs, 0, hcs)
        cubes[6].set_pos(-hcs, 0, -hcs)
        cubes[6].set_shape(shape_38, shape_42, shape_40, shape_41, shape_37, shape_39)

        cubes[7].set_trans(-hcs, -hcs, 0)
        cubes[7].set_pos(hcs, -hcs, 0)
        cubes[7].set_shape(shape_14, shape_18, shape_16, shape_17, shape_13, shape_15)

        shapes = {
            "1": shape_1,
            "2a": shape_2,
            "2b": shape_2,
            "3a": shape_3,
            "3b": shape_3,
            "4": shape_4,
            "5": shape_5,
            "6": shape_6,
            "7": shape_7,
            "8": shape_8,
            "9a": shape_9,
            "9b": shape_9,
            "10a": shape_10,
            "10b": shape_10,
            "11": shape_11,
            "12a": shape_12,
            "12b": shape_12,
            "13": shape_13,
            "14a": shape_14,
            "14b": shape_14,
            "15a": shape_15,
            "15b": shape_15,
            "16": shape_16,
            "17": shape_17,
            "18": shape_18,
            "19": shape_19,
            "20": shape_20,
            "21": shape_21,
            "22a": shape_22,
            "22b": shape_22,
            "23": shape_23,
            "24": shape_24,
            "25": shape_25,
            "26": shape_26,
            "27": shape_27,
            "28": shape_28,
            "29a": shape_29,
            "29b": shape_29,
            "30": shape_30,
            "31": shape_31,
            "32": shape_32,
            "33": shape_33,
            "34": shape_34,
            "35": shape_35,
            "36": shape_36,
            "37": shape_37,
            "38a": shape_38,
            "38b": shape_38,
            "39a": shape_39,
            "39b": shape_39,
            "40": shape_40,
            "41": shape_41,
            "42": shape_42,
            "43": shape_43,
            "44a": shape_44,
            "44b": shape_44,
            "45": shape_45,
            "46a": shape_46,
            "46b": shape_46,
            "47": shape_47,
            "48": shape_48,
        }
    };

    const keyPressed = p => {
        if (p.keyCode === 13) { // enter fullscreen
            let fs = p.fullscreen();
            p.fullscreen(!fs);
        }
        if (p.keyCode === 82) { // 82 == r
            zoom = !zoom
        }
        if (p.keyCode === 65) { // 65 == a
            if (manip == true) {
                manip = false
                if (launching) {
                    anim = -1
                    reverse = false
                    launching = false
                }
                angle = 0
                if (reverse == false) {
                    anim += 1
                }
                anim %= animation_list.length
                if (anim == 0) {
                    pos_y = 0
                    cubes[0].set_trans(-cs, 0, -hcs)
                    cubes[0].set_pos(hcs, -hcs, 0)
                    cubes[0].set_rot(0, 0, 0)
                }
                reverse = false
            }
        } 
        if (p.keyCode === 69) { // 69 == e
            if (manip == true) {
                manip = false
                if (launching) {
                    anim = 6
                    reverse = true
                    launching = false
                }
                angle = 0
                if (reverse == true) {
                    anim -= 1
                }
                if (anim < 0) {
                    anim = animation_list.length - 1
                }
                if (anim == 5) {
                    pos_y = -cs * 6
                    cubes[0].set_trans(0, cs * 5, 0)
                    cubes[0].set_pos(-hcs, hcs, -hcs)
                    cubes[0].set_rot(180, -180, -180)
                }
                reverse = true
            }
        }
        path_enable = path[current_shape][String(configuration)]
        if (p.keyCode === 70) { // 70 == f
            if (manip == true) {
                if (path_enable != null) {
                    if (path_enable[last_shape] != null) {
                        manip = false
                        piece_anim = true
                    }
                }
            }
        }
    }

    const mouseWheel = p => {
        if (cam_dist == 150 || cam_dist == 400) {
            zoom = !zoom
        }
    }

    const mouseMoved = p => {
        cam_angle_x += p.movedX
        cam_angle_y -= p.movedY
        p.redraw()
    }

    const draw = p => {
        p.background(bg)
        p.ambientLight(bg)

        let cam_pos_x = cam_dist * p.cos(cam_angle_y) * p.cos(cam_angle_x)
        let cam_pos_y = p.sin(cam_angle_y) * cam_dist
        let cam_pos_z = cam_dist * p.sin(cam_angle_x) * p.cos(cam_angle_y)
        p.camera(cam_pos_x, cam_pos_y, cam_pos_z, 0, 0, 0, 0, cam_up_y, 0)
        
        animation = animation_list[anim]

        function starting_point_from_up() {
            piece_pos_x = 0
            piece_pos_y = -hcs
        }
        function starting_point_from_down() {
            piece_pos_x = 0
            piece_pos_y = hcs
        }
        function starting_point_from_left() {
            piece_pos_x = -hcs
            piece_pos_y = 0
        }
        function starting_point_from_right() {
            piece_pos_x = hcs
            piece_pos_y = 0
        }
        start_point = {
            'up': starting_point_from_up,
            'down': starting_point_from_down,
            'left': starting_point_from_left,
            'right': starting_point_from_right
        }

        start_angle = {
            'model_1': null,
            'model_2': null,
            'model_3': null,
            'model_4': {
                'down': 180,
                'right': 270
            },
            'model_5': {
                'up': 0,
                'down': 180,
                'left': 90,
                'right': 270
            },
            'model_6': null,
            'model_7': null,
            'model_8': {
                'down': 180,
                'right': 270
            }
        }

        model_is = {
            "model_1": _model_1,
            "model_2": _model_2,
            "model_3": _model_3,
            "model_4": _model_4,
            "model_5": _model_5,
            "model_6": _model_6,
            "model_7": _model_7,
            "model_8": _model_8
        }
        // piece animation
        if (piece_anim == true) {
            model_is[shapes[current_shape].get_model_name()](p)
        }

        handle_zoom()

        handle_camera_direction(p)

        handle_animation_cube()

        for (let pl of planets) {
            pl.show()
        }
        p.translate(0, pos_y, 0)
        try {
            for (let c of cubes) {
                c.show()
            }
        } catch (err) {
            console.log(err)
        }
    };

    return <Sketch setup={setup} draw={draw} keyPressed={keyPressed} mouseMoved={mouseMoved} mouseWheel={mouseWheel} />;
};

class Cube {
    constructor(p, x, y, z) {
        this.p = p
        this.pos = p.createVector(x, y, z)
        this.trans = p.createVector(0, 0, 0)
        this.rot = p.createVector(0, 0, 0)
        this.r = 0
        this.g = 0
        this.b = 0
        this.list_shape = {
            'shape_1': empty_shape,
            'shape_2': empty_shape,
            'shape_3': empty_shape,
            'shape_4': empty_shape,
            'shape_5': empty_shape,
            'shape_6': empty_shape
        }
    }
    set_pos(x, y, z) {
        this.pos = this.p.createVector(x, y, z)
    }
    set_rot(x, y, z) {
        this.rot = this.p.createVector(x, y, z)
    }
    set_color(r, g, b) {
        this.r = r
        this.g = g
        this.b = b
    }
    set_trans(x, y, z) {
        this.trans = this.p.createVector(x, y, z)
    }
    set_shape(shape_1, shape_2, shape_3, shape_4, shape_5, shape_6) {
        this.list_shape['shape_1'] = shape_1 // right
        this.list_shape['shape_2'] = shape_2 // front
        this.list_shape['shape_3'] = shape_3 // left
        this.list_shape['shape_4'] = shape_4 // back
        this.list_shape['shape_5'] = shape_5 // up
        this.list_shape['shape_6'] = shape_6 // down
    }
    show() {
        this.p.translate(this.trans.x, this.trans.y, this.trans.z) // translate axis rotation
        this.p.rotateX(this.rot.x)
        this.p.rotateY(this.rot.y)
        this.p.rotateZ(this.rot.z)
        this.list_shape['shape_1'].show(this.pos.x + hcs, this.pos.y, this.pos.z)
        this.list_shape['shape_2'].show(this.pos.x, this.pos.y, this.pos.z + hcs)
        this.list_shape['shape_3'].show(this.pos.x - hcs, this.pos.y, this.pos.z)
        this.list_shape['shape_4'].show(this.pos.x, this.pos.y, this.pos.z - hcs)
        this.list_shape['shape_5'].show(this.pos.x, this.pos.y - hcs, this.pos.z)
        this.list_shape['shape_6'].show(this.pos.x, this.pos.y + hcs, this.pos.z)
        this.p.translate(this.pos.x, this.pos.y, this.pos.z) // translate box around axis rotation
    }
}

class Shape {
    constructor(p, id, model_name, model, agl_x, agl_y, agl_z, color) {
        this.p = p
        this.id = id
        this.model = model
        this.model_name = model_name
        this.agl_x = agl_x
        this.agl_y = agl_y
        this.agl_z = agl_z
        this.color = color
    }
    get_id() {
        return this.id
    }
    get_model_name() {
        return this.model_name
    }
    show(x, y, z) {
        this.p.push()
        this.p.fill(this.color)
        this.p.noStroke()
        this.p.translate(x, y, z)
        this.p.rotateX(this.agl_x)
        this.p.rotateY(this.agl_y)
        this.p.rotateZ(this.agl_z)
        this.p.plane(cs)
        this.model(this.p)
        if (this.id == 1 || this.id == 48) {
            flag(this.p)
        }
        if (shape_id == this.id) {
            piece.set_pos(piece_pos_x, piece_pos_y)
            piece.show()
        }
        this.p.pop()
    }
}

class Piece {
    constructor(p) {
        this.p = p
        this.x = 0
        this.y = 0
        this.z = 0
        this.color = red
    }
    set_pos(x, y) {
        this.x = x
        this.y = y
    }
    set_color(color) {
        this.color = color
    }
    show() {
        this.p.push()
        this.p.fill(this.color)
        this.p.noStroke()
        this.p.translate(this.x, this.y, this.z)
        this.p.sphere(2.5)
        this.p.pop()
    }
}

class Planet {
    constructor(p) {
        this.p = p
        this.max_dist = 1500
        this.min_dist = 1000
        this.min_size = 10
        this.max_size = 30
        this.min_color = 100
        this.max_color = 200
        this.dist = Math.random() * (this.max_dist - this.min_dist + 1) + this.min_dist;
        this.size = Math.random() * (this.max_size - this.min_size + 1) + this.min_size;
        this.r = Math.random() * (this.max_color - this.min_color + 1) + this.min_color;
        this.g = Math.random() * (this.max_color - this.min_color + 1) + this.min_color;
        this.b = Math.random() * (this.max_color - this.min_color + 1) + this.min_color;
        this.agl_x = Math.random() * 360
        this.agl_y = Math.random() * 360
        this.pos_x = this.dist * this.p.cos(this.agl_y) * this.p.cos(this.agl_x)
        this.pos_y = this.p.sin(this.agl_y) * this.dist
        this.pos_z = this.dist * this.p.sin(this.agl_x) * this.p.cos(this.agl_y)
    }
    show() {
        this.p.push()
        this.p.translate(this.pos_x, this.pos_y, this.pos_z)
        this.p.fill(this.r, this.g, this.b)
        this.p.noStroke()
        this.p.sphere(this.size)
        this.p.pop()
    }
}

function handle_zoom() {
    if (zoom === true) {
        cam_dist -= 10
        if (cam_dist <= 150) {
            cam_dist = 150
        }
    } else {
        cam_dist += 10
        if (cam_dist >= 500) {
            cam_dist = 500
        }
    }
}

function handle_camera_direction(p) {
    if (p.keyIsDown(68)) { // key: 'd' --> camera right
        cam_angle_x -= cam_rot_speed
    }
    if (p.keyIsDown(81)) { // key: 'q' --> camera left
        cam_angle_x += cam_rot_speed
    }
    if (p.keyIsDown(90)) { // key: 'z' --> camera up
        cam_angle_y -= cam_rot_speed
    }
    if (p.keyIsDown(83)) { // key: 's' --> camera down
        cam_angle_y += cam_rot_speed
    }
    if (cam_angle_y > 90 || cam_angle_y < -90) {
        cam_up_y = -1
    } else {
        cam_up_y = 1
    }
    if (cam_angle_y < -179) {
        cam_angle_y = 180
    } else if (cam_angle_y > 180) {
        cam_angle_y = -179
    }
}

function handle_animation_cube() {

    function angle_rotation() {
        angle += rot_speed
        if (angle > 180) {
            angle = 180
            if (piece_anim == false) {
                manip = true
            }
        }
    }

    if (animation === 0) {
        if (reverse) {
            angle_rotation()
            pos_y += hcs / (180 / rot_speed)
            if (pos_y > 0) { // -25
                pos_y = 0
                configuration = 0
            }
            cubes[0].set_trans(-cs, 0, -hcs)
            cubes[0].set_pos(hcs, -hcs, 0)
            cubes[0].set_rot(0, 0, -180 + angle)

            cubes[1].set_rot(0, 0, 180 - angle)
            cubes[3].set_rot(0, 0, 180 - angle)
            cubes[5].set_rot(0, 0, -180 + angle)
            cubes[7].set_rot(0, 0, -180 + angle)
        } else {
            angle_rotation()
            pos_y -= hcs / (180 / rot_speed)
            if (pos_y < -hcs) { // -25
                pos_y = -hcs
                configuration = 1
            }
            cubes[0].set_rot(0, 0, -angle)

            cubes[1].set_rot(0, 0, angle)
            cubes[3].set_rot(0, 0, angle)
            cubes[5].set_rot(0, 0, -angle)
            cubes[7].set_rot(0, 0, -angle)
        }
    }

    if (animation === 1) {
        if (reverse) {
            angle_rotation()
            pos_y += hcs / (90 / rot_speed)
            if (pos_y > -hcs) { // -75
                pos_y = -hcs
                configuration = 1
            }
            cubes[0].set_trans(-cs, cs, 0)
            cubes[0].set_pos(hcs, hcs, -hcs)
            cubes[0].set_rot(90 - angle / 2, 0, -180)

            cubes[4].set_rot(180 - angle, 0, 0)
        } else {
            angle_rotation()
            pos_y -= hcs / (90 / rot_speed)
            if (pos_y < -hcs * 3) { // -75
                pos_y = -hcs * 3
                configuration = 2
            }
            cubes[0].set_trans(-cs, cs, 0)
            cubes[0].set_pos(hcs, hcs, -hcs)
            cubes[0].set_rot(angle / 2, 0, -180)

            cubes[4].set_rot(angle, 0, 0)
        }
    }

    if (animation === 2) {
        if (reverse) {
            angle_rotation()
            pos_y += hcs * 3 / (180 / rot_speed)
            if (pos_y > -hcs * 3) { // -150
                pos_y = -hcs * 3
                configuration = 2
            }
            cubes[0].set_trans(0, cs * 2, 0)
            cubes[0].set_pos(hcs + cs, hcs, hcs)
            cubes[0].set_rot(90, -90 + angle / 2, -180)

            cubes[2].set_rot(0, 180 - angle, 0)
            cubes[6].set_rot(0, 180 - angle, 0)
        } else {
            angle_rotation()
            pos_y -= hcs * 3 / (180 / rot_speed)
            if (pos_y < -cs * 3) { // -150
                pos_y = -cs * 3
                configuration = 3
            }
            cubes[0].set_trans(0, cs * 2, 0)
            cubes[0].set_pos(hcs + cs, hcs, hcs)
            cubes[0].set_rot(90, -angle / 2, -180)

            cubes[2].set_rot(0, angle, 0)
            cubes[6].set_rot(0, angle, 0)
        }
    }

    if (animation === 3) {
        if (reverse) {
            angle_rotation()
            pos_y += hcs / (180 / rot_speed)
            if (pos_y > -cs * 3) { // -175
                pos_y = -cs * 3
                configuration = 3
            }
            cubes[1].set_rot(0, 0, angle)
            cubes[3].set_rot(0, 0, angle)
            cubes[5].set_rot(0, 0, 360 - angle)
            cubes[7].set_rot(0, 0, 360 - angle)
        } else {
            angle_rotation()
            pos_y -= hcs / (180 / rot_speed)
            if (pos_y < -hcs * 7) { // -175
                pos_y = -hcs * 7
                configuration = 4
            }
            cubes[1].set_rot(0, 0, -angle + 180)
            cubes[3].set_rot(0, 0, -angle + 180)
            cubes[5].set_rot(0, 0, angle + 180)
            cubes[7].set_rot(0, 0, angle + 180)
        }
    }

    if (animation === 4) {
        if (reverse) {
            angle_rotation()
            pos_y += hcs / (90 / rot_speed)
            if (pos_y > -hcs * 7) { // -275
                pos_y = -hcs * 7
                configuration = 4
            }
            cubes[0].set_trans(0, cs * 4, 0)
            cubes[0].set_pos(-hcs, hcs, hcs)
            cubes[0].set_rot(90, -180 + angle / 2, -180)

            cubes[2].set_rot(0, angle, 0)
            cubes[6].set_rot(0, angle, 0)
        } else {
            angle_rotation()
            pos_y -= hcs / (90 / rot_speed)
            if (pos_y < -hcs * 9) { // -275
                pos_y = -hcs * 9
                configuration = 5
            }
            cubes[0].set_trans(0, cs * 4, 0)
            cubes[0].set_pos(-hcs, hcs, hcs)
            cubes[0].set_rot(90, -angle / 2 - 90, -180)

            cubes[2].set_rot(0, -angle + 180, 0)
            cubes[6].set_rot(0, -angle + 180, 0)
        }
    }

    if (animation === 5) {
        if (reverse) {
            angle_rotation()
            pos_y += hcs * 3 / (180 / rot_speed)
            if (pos_y > -hcs * 9) { // -300
                pos_y = -hcs * 9
                configuration = 5
            }
            cubes[0].set_trans(0, cs * 5, 0)
            cubes[0].set_pos(-hcs, hcs, -hcs)
            cubes[0].set_rot(180 - angle / 2, -180, -180)

            cubes[4].set_rot(angle, 0, 0)
        } else {
            angle_rotation()
            pos_y -= hcs * 3 / (180 / rot_speed)
            if (pos_y < -cs * 6) { // -300
                pos_y = -cs * 6
                configuration = 0
            }
            cubes[0].set_trans(0, cs * 5, 0)
            cubes[0].set_pos(-hcs, hcs, -hcs)
            cubes[0].set_rot(angle / 2 + 90, -180, -180)

            cubes[4].set_rot(-angle + 180, 0, 0)
        }
    }
}


function empty_shape() {
    return undefined
}

function flag(p) {
    p.noFill()
    p.stroke(0)
    p.beginShape()
    p.vertex(0, 0, 0)
    p.vertex(0, 0, hcs)
    p.vertex(0, -hcs / 2, hcs * 3 / 4)
    p.vertex(0, 0, hcs / 2)
    p.endShape()
}

function model_1(p) { // dead-end
    p.noFill()
    p.stroke(0)
    p.beginShape()
    p.vertex(-hcs / 5, hcs, 0)
    p.vertex(-hcs / 5, -hcs / 5, 0)
    p.vertex(hcs / 5, -hcs / 5, 0)
    p.vertex(hcs / 5, hcs, 0)
    p.endShape()
}
function model_2(p) { // cross road
    p.noFill()
    p.stroke(0)
    p.beginShape(p.LINES)
    p.vertex(-hcs / 5, -hcs, 0)
    p.vertex(-hcs / 5, hcs, 0)
    p.vertex(hcs / 5, hcs, 0)
    p.vertex(hcs / 5, -hcs, 0)
    p.vertex(-hcs, -hcs / 5, 0)
    p.vertex(-hcs / 5, -hcs / 5, 0)
    p.vertex(hcs / 5, -hcs / 5, 0)
    p.vertex(hcs, -hcs / 5, 0)
    p.vertex(-hcs, hcs / 5, 0)
    p.vertex(-hcs / 5, hcs / 5, 0)
    p.vertex(hcs / 5, hcs / 5, 0)
    p.vertex(hcs, hcs / 5, 0)
    p.endShape()
}
function model_3(p) { // straight road
    p.noFill()
    p.stroke(0)
    p.beginShape(p.LINES)
    p.vertex(-hcs / 5, -hcs, 0)
    p.vertex(-hcs / 5, hcs, 0)
    p.vertex(hcs / 5, hcs, 0)
    p.vertex(hcs / 5, -hcs, 0)
    p.endShape()
}
function model_4(p) { // turning point
    p.noFill()
    p.stroke(0)
    p.beginShape(p.LINES)
    p.vertex(-hcs / 5, hcs, 0)
    p.quadraticVertex(-hcs / 8, -hcs / 8, 0, hcs, -hcs / 5, 0)
    p.vertex(hcs / 5, hcs, 0)
    p.quadraticVertex(hcs / 4, hcs / 4, 0, hcs, hcs / 5, 0)
    p.endShape()
}
function model_5(p) { // double turning point
    p.noFill()
    p.stroke(0)
    p.beginShape(p.LINES)
    p.vertex(-hcs, hcs / 5, 0)
    p.quadraticVertex(hcs / 8, hcs / 8, 0, hcs / 5, -hcs, 0)
    p.vertex(-hcs, -hcs / 5, 0)
    p.quadraticVertex(-hcs / 4, -hcs / 4, 0, -hcs / 5, -hcs, 0)
    p.vertex(-hcs / 5, hcs, 0)
    p.quadraticVertex(-hcs / 8, -hcs / 8, 0, hcs, -hcs / 5, 0)
    p.vertex(hcs / 5, hcs, 0)
    p.quadraticVertex(hcs / 4, hcs / 4, 0, hcs, hcs / 5, 0)
    p.endShape()
}
function model_6(p) { // double dead-end
    p.noFill()
    p.stroke(0)
    p.beginShape()
    p.vertex(-hcs / 5, hcs, 0)
    p.vertex(-hcs / 5, hcs - hcs / 2, 0)
    p.vertex(hcs / 5, hcs - hcs / 2, 0)
    p.vertex(hcs / 5, hcs, 0)
    p.endShape()
    p.beginShape()
    p.vertex(hcs, -hcs / 5, 0)
    p.vertex(hcs - hcs / 2, -hcs / 5, 0)
    p.vertex(hcs - hcs / 2, hcs / 5, 0)
    p.vertex(hcs, hcs / 5, 0)
    p.endShape()
}
function model_7(p) { // straight line & dead-end
    p.noFill()
    p.stroke(0)
    p.beginShape(p.LINES)
    p.vertex(-hcs, hcs / 5, 0)
    p.vertex(hcs, hcs / 5, 0)
    p.vertex(hcs, -hcs / 5, 0)
    p.vertex(-hcs, -hcs / 5, 0)
    p.endShape()
    p.beginShape()
    p.vertex(-hcs / 5, -hcs, 0)
    p.vertex(-hcs / 5, -hcs + hcs / 2, 0)
    p.vertex(hcs / 5, -hcs + hcs / 2, 0)
    p.vertex(hcs / 5, -hcs, 0)
    p.endShape()
}
function model_8(p) { // turning point & dead-end
    p.noFill()
    p.stroke(0)
    p.beginShape()
    p.vertex(-hcs / 5, -hcs, 0)
    p.vertex(-hcs / 5, -hcs + hcs / 2, 0)
    p.vertex(hcs / 5, -hcs + hcs / 2, 0)
    p.vertex(hcs / 5, -hcs, 0)
    p.endShape()
    p.beginShape(p.LINES)
    p.vertex(-hcs / 5, hcs, 0)
    p.quadraticVertex(-hcs / 8, -hcs / 8, 0, hcs, -hcs / 5, 0)
    p.vertex(hcs / 5, hcs, 0)
    p.quadraticVertex(hcs / 4, hcs / 4, 0, hcs, hcs / 5, 0)
    p.endShape()
}


//////////////  animation path model_1  ///////////////
function _model_1(p) {
    if (is_input) {
        // enter into a new shape
        come_from_down()
    } else {
        // exit from the current shape
        exit_to_down()
    }
}
//////////////  animation path model_2  ///////////////
function _model_2(p) {
    if (come_from == 'up') {
        if (is_input) {
            // enter into a new shape
            come_from_up()
        } else {
            // exit from the current shape
            exit_to_down()
        }
    }
    else if (come_from == 'down') {
        if (is_input) {
            // enter into a new shape
            come_from_down()
        } else {
            // exit from the current shape
            exit_to_up()
        }
    }
    else if (come_from == 'left') {
        if (is_input) {
            // enter into a new shape
            come_from_left()
        } else {
            // exit from the current shape
            exit_to_right()
        }
    }
    else if (come_from == 'right') {
        if (is_input) {
            // enter into a new shape
            come_from_right()
        } else {
            // exit from the current shape
            exit_to_left()
        }
    }
}
//////////////  animation path model_3  ///////////////
function _model_3(p) {
    if (come_from == 'up') {
        if (is_input) {
            // enter into a new shape
            come_from_up()
        } else {
            // exit from the current shape
            exit_to_down()
        }
    }
    else if (come_from == 'down') {
        if (is_input) {
            // enter into a new shape
            come_from_down()
        } else {
            // exit from the current shape
            exit_to_up()
        }
    }
}
//////////////  animation path model_4  ///////////////
function _model_4(p) {
    if (come_from == 'down') {
        if (is_input) {
            // enter into a new shape
            come_turning_from_down(p)
        } else {
            // exit from the current shape
            exit_turning_to_right(p)
        }
    }
    else if (come_from == 'right') {
        if (is_input) {
            // enter into a new shape
            come_turning_from_right(p)
        } else {
            // exit from the current shape
            exit_turning_to_down(p)
        }
    }
}
//////////////  animation path model_5  ///////////////
function _model_5(p) {
    if (come_from == 'up') {
        if (is_input) {
            // enter into a new shape
            come_turning_from_up(p)
        } else {
            // exit from the current shape
            exit_turning_to_left(p)
        }
    }
    else if (come_from == 'down') {
        if (is_input) {
            // enter into a new shape
            come_turning_from_down(p)
        } else {
            // exit from the current shape
            exit_turning_to_right(p)
        }
    }
    else if (come_from == 'left') {
        if (is_input) {
            // enter into a new shape
            come_turning_from_left(p)
        } else {
            // exit from the current shape
            exit_turning_to_up(p)
        }
    }
    else if (come_from == 'right') {
        if (is_input) {
            // enter into a new shape
            come_turning_from_right(p)
        } else {
            // exit from the current shape
            exit_turning_to_down(p)
        }
    }
}
//////////////  animation path model_6  ///////////////
function _model_6(p) {
    if (come_from == 'down') {
        if (is_input) {
            // enter into a new shape
            come_from_down(10)
        } else {
            // exit from the current shape
            exit_to_down()
        }
    }
    else if (come_from == 'right') {
        if (is_input) {
            // enter into a new shape
            come_from_right(10)
        } else {
            // exit from the current shape
            exit_to_right()
        }
    }
}
//////////////  animation path model_7  ///////////////
function _model_7(p) {
    if (come_from == 'up') {
        if (is_input) {
            // enter into a new shape
            come_from_up(-10)
        } else {
            // exit from the current shape
            exit_to_up()
        }
    }
    else if (come_from == 'left') {
        if (is_input) {
            // enter into a new shape
            come_from_left()
        } else {
            // exit from the current shape
            exit_to_right()
        }
    }
    else if (come_from == 'right') {
        if (is_input) {
            // enter into a new shape
            come_from_right()
        } else {
            // exit from the current shape
            exit_to_left()
        }
    }
}
//////////////  animation path model_8  ///////////////
function _model_8(p) {
    if (come_from == 'up') {
        if (is_input) {
            // enter into a new shape
            come_from_up(-10)
        } else {
            // exit from the current shape
            exit_to_up()
        }
    }
    else if (come_from == 'down') {
        if (is_input) {
            // enter into a new shape
            come_turning_from_down(p)
        } else {
            // exit from the current shape
            exit_turning_to_right(p)
        }
    }
    else if (come_from == 'right') {
        if (is_input) {
            // enter into a new shape
            come_turning_from_right(p)
        } else {
            // exit from the current shape
            exit_turning_to_down(p)
        }
    }
}

function next_shape() {
    new_shape = path_enable[last_shape]
    last_shape = current_shape
    current_shape = new_shape[0]
    shape_id = shapes[current_shape].get_id()
    shape_model_name = shapes[current_shape].get_model_name()
    come_from = new_shape[1]
    start_point[come_from]()
    if (start_angle[shape_model_name] != null) {
        agl_rot_piece = start_angle[shape_model_name][come_from]
    }
    is_input = true
}

function come_from_up(val = 0) {
    piece_pos_y += piece_anim_speed
    if (piece_pos_y >= val) {
        piece_pos_y = val
        is_input = false
        piece_anim = false
    }
}
function come_from_down(val = 0) {
    piece_pos_y -= piece_anim_speed
    if (piece_pos_y <= val) {
        piece_pos_y = val
        is_input = false
        piece_anim = false
    }
}
function come_from_left(val = 0) {
    piece_pos_x += piece_anim_speed
    if (piece_pos_x >= val) {
        piece_pos_x = val
        is_input = false
        piece_anim = false
    }
}
function come_from_right(val = 0) {
    piece_pos_x -= piece_anim_speed
    if (piece_pos_x <= val) {
        piece_pos_x = val
        is_input = false
        piece_anim = false
    }
}
function exit_to_up() {
    piece_pos_y -= piece_anim_speed
    if (piece_pos_y <= -hcs) {
        next_shape()
    }
}
function exit_to_down() {
    piece_pos_y += piece_anim_speed
    if (piece_pos_y >= hcs) {
        next_shape()
    }
}
function exit_to_left() {
    piece_pos_x -= piece_anim_speed
    if (piece_pos_x <= -hcs) {
        next_shape()
    }
}
function exit_to_right() {
    piece_pos_x += piece_anim_speed
    if (piece_pos_x >= hcs) {
        next_shape()
    }
}
function come_turning_from_up(p) {
    agl_rot_piece += agl_rot_piece_speed
    piece_pos_x = hcs * p.cos(agl_rot_piece) - hcs
    piece_pos_y = hcs * p.sin(agl_rot_piece) - hcs
    if (agl_rot_piece >= 45) {
        agl_rot_piece = 45
        is_input = false
        piece_anim = false
    }
}
function come_turning_from_down(p) {
    agl_rot_piece += agl_rot_piece_speed
    piece_pos_x = hcs * p.cos(agl_rot_piece) + hcs
    piece_pos_y = hcs * p.sin(agl_rot_piece) + hcs
    if (agl_rot_piece >= 225) {
        agl_rot_piece = 225
        is_input = false
        piece_anim = false
    }
}
function come_turning_from_left(p) {
    agl_rot_piece -= agl_rot_piece_speed
    piece_pos_x = hcs * p.cos(agl_rot_piece) - hcs
    piece_pos_y = hcs * p.sin(agl_rot_piece) - hcs
    if (agl_rot_piece <= 45) {
        agl_rot_piece = 45
        is_input = false
        piece_anim = false
    }
}
function come_turning_from_right(p) {
    agl_rot_piece -= agl_rot_piece_speed
    piece_pos_x = hcs * p.cos(agl_rot_piece) + hcs
    piece_pos_y = hcs * p.sin(agl_rot_piece) + hcs
    if (agl_rot_piece <= 225) {
        agl_rot_piece = 225
        is_input = false
        piece_anim = false
    }
}
function exit_turning_to_up(p) {
    agl_rot_piece -= agl_rot_piece_speed
    piece_pos_x = hcs * p.cos(agl_rot_piece) - hcs
    piece_pos_y = hcs * p.sin(agl_rot_piece) - hcs
    if (agl_rot_piece <= 0) {
        next_shape()
    }
}
function exit_turning_to_down(p) {
    agl_rot_piece -= agl_rot_piece_speed
    piece_pos_x = hcs * p.cos(agl_rot_piece) + hcs
    piece_pos_y = hcs * p.sin(agl_rot_piece) + hcs
    if (agl_rot_piece <= 180) {
        next_shape()
    }
}
function exit_turning_to_left(p) {
    agl_rot_piece += agl_rot_piece_speed
    piece_pos_x = hcs * p.cos(agl_rot_piece) - hcs
    piece_pos_y = hcs * p.sin(agl_rot_piece) - hcs
    if (agl_rot_piece >= 90) {
        next_shape()
    }
}
function exit_turning_to_right(p) {
    agl_rot_piece += agl_rot_piece_speed
    piece_pos_x = hcs * p.cos(agl_rot_piece) + hcs
    piece_pos_y = hcs * p.sin(agl_rot_piece) + hcs
    if (agl_rot_piece >= 270) {
        next_shape()
    }
}