import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

class Home extends Component {

    render() {
        const { classes } = this.props
        return (
            <p className={classes.title}>Home</p>
        )
    }
}

const styles = theme => ({
    title: {
        textAlign: 'center'
    }
});

const styledHome = withStyles(styles)(Home);
export { styledHome as Home };