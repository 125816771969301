import React from "react";
import Sketch from "react-p5";


const cache = {}
function importAll(r) {
    r.keys().forEach(key => cache[key] = r(key))
}
importAll(require.context('./img', true, /\.(png)$/))

let total_filtres = 5
let filtre = 0
let ratio = 1.5
let prev_pos = -600
let next_pos = 0
let anim = false
let speed = 15
let launch = false
let reverse = false
let article
let fil_1
let fil_2
let fil_3
let fil_4
let filtre_1
let filtre_2
let filtre_3
let filtre_4

export const news_paper = () => {
    
    const setup = p => {
        p.imageMode(p.CENTER)
        p.createCanvas(600, 600).parent('paper');
        article = p.loadImage(cache["./article.png"].default)
        fil_1 = p.loadImage(cache["./filtre_1.png"].default)
        fil_2 = p.loadImage(cache["./filtre_2.png"].default)
        fil_3 = p.loadImage(cache["./filtre_3.png"].default)
        fil_4 = p.loadImage(cache["./filtre_4.png"].default)

        filtre_1 = new Filtre(p, fil_1)
        filtre_2 = new Filtre(p, fil_2)
        filtre_3 = new Filtre(p, fil_3)
        filtre_4 = new Filtre(p, fil_4)
    }

    const draw = p => {
        p.background(p.color(230, 230, 230))
        p.image(article, p.width / 2, p.height / 2, article.width * ratio, article.height * ratio)

        if (anim == true) {
            if (reverse == false) {
                prev_pos += speed
                next_pos += speed
                if (prev_pos >= 0) {
                    prev_pos = 0
                    next_pos = 600
                    anim = false
                }
            } else {
                prev_pos -= speed
                next_pos -= speed
                if (next_pos <= 0) {
                    next_pos = 0
                    prev_pos = -600
                    anim = false
                }
            }
        }

        if (filtre == 0) {
            if (reverse) {
                filtre_1.set_pos(prev_pos)
                filtre_1.show()
            } else {
                if (launch) {
                    filtre_4.set_pos(next_pos)
                    filtre_4.show()
                }
            }
        }

        if (filtre == 1) {
            if (reverse) {
                filtre_2.set_pos(prev_pos)
                filtre_1.set_pos(next_pos)
                filtre_2.show()
                filtre_1.show()
            } else {
                filtre_1.set_pos(prev_pos)
                filtre_1.show()
            }
        }
        if (filtre == 2) {
            if (reverse) {
                filtre_3.set_pos(prev_pos)
                filtre_2.set_pos(next_pos)
                filtre_3.show()
                filtre_2.show()
            } else {
                filtre_1.set_pos(next_pos)
                filtre_2.set_pos(prev_pos)
                filtre_1.show()
                filtre_2.show()
            }
        }
        if (filtre == 3) {
            if (reverse) {
                filtre_4.set_pos(prev_pos)
                filtre_3.set_pos(next_pos)
                filtre_4.show()
                filtre_3.show()
            } else {
                filtre_2.set_pos(next_pos)
                filtre_3.set_pos(prev_pos)
                filtre_2.show()
                filtre_3.show()
            }
        }
        if (filtre == 4) {
            if (reverse) {
                filtre_4.set_pos(next_pos)
                filtre_4.show()
            } else {
                filtre_3.set_pos(next_pos)
                filtre_4.set_pos(prev_pos)
                filtre_3.show()
                filtre_4.show()
            }
        }
    }

    const keyPressed = p => {
        if (p.keyCode === 13) { // enter fullscreen
            let fs = p.fullscreen();
            p.fullscreen(!fs);
        }
        if (p.keyCode === p.LEFT_ARROW) {
            if (anim == false) {
                filtre -= 1
                if (filtre == -1) {
                    filtre = total_filtres - 1
                }
                prev_pos = 0
                next_pos = 600
                launch = true
                anim = true
                reverse = true
            }
        }
        if (p.keyCode === p.RIGHT_ARROW) {
            if (anim == false) {
                filtre += 1
                filtre %= total_filtres
                prev_pos = -600
                next_pos = 0
                launch = true
                anim = true
                reverse = false
            }
        }
    }
    return <Sketch setup={setup} draw={draw} keyPressed={keyPressed} />;
}

class Filtre {
    constructor(p, img) {
        this.p = p
        this.img = img
        this.current_pos = -600
    }
    get_pos() {
        return this.current_pos
    }
    set_pos(pos) {
        this.current_pos = pos
    }
    show() {
        this.p.push()
        this.p.translate(this.current_pos, 0, 0, 0)
        this.p.push()
        this.p.noStroke()
        this.p.noFill()
        this.p.rect(0, 0, 600, 600)
        this.p.pop()
        this.p.image(this.img, this.p.width / 2, this.p.height / 2, this.img.width * ratio, this.img.height * ratio)
        this.p.pop()
    }
}