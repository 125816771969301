import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import marked from "marked";
import novel from '../novel/novel.md'

class Novel extends Component {

  render() {
    const { classes } = this.props
    const fetchText = () => {
      fetch(novel)
      .then(response => {
        return response.text()
      })
      .then(text => {
        document.getElementById('novel').innerHTML = marked(text)
      })
    }
    return(
      <div className={classes.root}>
        {fetchText()}
        <div className={classes.mainContainer}>
          <div/>
          <Typography
            className={classes.typo}
            id='novel'
            variant="body2"
            color="textSecondary"
            component="div"
          />
          <div/>
        </div>
      </div>
    )
  }

}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70%'
  },
  typo: {
    border: 'none',
    textAlign: 'justify',
    width: '60%',
  }
})

const styledNovel = withStyles(styles)(Novel)
export { styledNovel as Novel }
