import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import tab from '../data_txt.json'


const cache = {}
function importAll (r) {
  r.keys().forEach(key => cache[key] = r(key))
}
importAll(require.context('../txt', true, /\.txt$/))


class Texts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      newTab: Object.values(tab)
    }
  }

  render() {
    const { value, newTab } = this.state
    const { classes } = this.props
    const buttonBack = () => {
      return (
        <Button
        className={classes.button}
        onClick={this.decrease}
        ><ArrowBack /></Button>
      )
    }
    const buttonForward = () => {
      return (
        <Button
        className={classes.button}
        onClick={this.increase}
        ><ArrowForward /></Button>
      )
    }
    const fetchText = () => {
      fetch(cache[newTab[value].path].default)
      .then(response => {
        return response.text()
      })
      .then(text => {
        document.getElementById('text').innerHTML = text
      })
    }
    return(
      <div className={classes.root}>
        {fetchText()}
        <div className={classes.mainContainer}>
          <div className={classes.buttonContainer}>
            <div className={classes.buttonNav}>
              {buttonBack()}
              {buttonForward()}
            </div>
            <Button onClick={this.filterByTag} value="story">histoire</Button>
            <Button onClick={this.filterByTag} value="quote">citation</Button>
            <Button onClick={this.filterByTag} value="black hole">trou noir</Button>
            <Button onClick={this.filterByTag} value="all">tous</Button>
          </div>
          <Typography
            className={classes.typo}
            id='text'
            variant="body2"
            color="textSecondary"
            component="div"
          />
        </div>
      </div>
    )
  }

  decrease = () => {
    const { value, newTab } = this.state
    let copyValue = value
    if (value === 0) {
      copyValue = newTab.length
    }
    this.setState({value: (copyValue - 1) % newTab.length})
  }

  increase = () => {
    this.setState({value: (this.state.value + 1) % this.state.newTab.length})
  }

  filterByTag = (e) => {
    const tag = e.currentTarget.value
    const newTab = []
    if (tag === 'all') {
      this.setState({value: 0, newTab: Object.values(tab)})
    } else {
      Object.values(tab).map(el => {
        if (tag === el.tag) {
          newTab.push(el)
        }
        return null
      })
      this.setState({value: 0, newTab: newTab})
    }
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '2%',
    width: '70%'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '2%'
  },
  buttonNav: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    width: "30px",
    height: "30px",
    margin: "10px 0px 10px 0px",
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'blue',
    width: '100%',
    alignItems: 'center',
  },
  typo: {
    border: 'none',
    textAlign: 'justify',
    width: '60%',
  }
})

const styledTexts = withStyles(styles)(Texts)
export { styledTexts as Texts }
