import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Cube } from './cube'
import { Newspaper } from './newspaper'
import {
    Switch,
    Route,
    Link
} from "react-router-dom";
import { withRouter } from "react-router";

class Program extends Component {

  render() {
    const { match, classes } = this.props
    return (
      <div className={classes.root}>
        <ul className={classes.ul}>
          <li>
            <Link className={classes.button} to='/program/cube'>Le cube</Link>
          </li>
          <li>
            <Link className={classes.button} to='/program/newspaper'>Entre les lignes</Link>
          </li>
        </ul>
        <Switch>
          <Route path={`${match.url}/cube`}>
            <Cube />
          </Route>
          <Route path={`${match.url}/newspaper`}>
            <Newspaper />
          </Route>
        </Switch>
      </div>
    )
  }
}

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        
    },
    ul: {
      listStyle: 'none',
      display: "flex",
      flexDirection: "row",
    },
    button: {
        "&:hover": {
            background: '#666633',
            color: 'white'
        },
        fontSize: 17,
        background: 'white',
        color: '#666633',
        textDecoration: 'none',
        border: '1px solid #666633',
        padding: 10,
        marginRight: 10,
        transitionDuration: '0.5s'
    }
});

const styledProgram = withStyles(styles)(Program);
const withRouterProgram = withRouter(styledProgram)
export { withRouterProgram as Program };