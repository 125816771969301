import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import KeyboardReturn from '@material-ui/icons/KeyboardReturn'

import tab from '../data_img.json'

const cache = {}
function importAll (r) {
  r.keys().forEach(key => cache[key] = r(key))
}
importAll(require.context('../img', true, /\.(jpg|jpeg)$/))

class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      isOpen: false,
      newTab: Object.values(tab)
    }
  }

  render() {
    const { value, newTab } = this.state
    const { classes } = this.props
    const buttonBack = () => {
      return (
        <Button
        className={classes.button}
        onClick={this.decrease}
        ><ArrowBack /></Button>
      )
    }
    const buttonForward = () => {
      return (
        <Button
        className={classes.button}
        onClick={this.increase}
        ><ArrowForward /></Button>
      )
    }
    return(
      <div>
        <div className={classes.root}>
          <div className={classes.mainContainer}>

            <div className={classes.buttonContainer}>
              <div className={classes.buttonNav}>
                {buttonBack()}
                {buttonForward()}
              </div>
              <Button onClick={this.filterByTag} value="dessin">dessin</Button>
              <Button onClick={this.filterByTag} value="peinture">peinture</Button>
              <Button onClick={this.filterByTag} value="sculpture">objet</Button>
              <Button onClick={this.filterByTag} value="game-design">game-design</Button>
              <Button onClick={this.filterByTag} value="all">tous</Button>
            </div>

            <Card
            onClick={this.handleOpen}
            className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.img}
                  component="img"
                  image={cache[newTab[value].path].default}
                  title=""
                  alt=""
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {newTab[value].title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {newTab[value].description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div>


        <Dialog
          fullScreen={true}
          open={this.state.isOpen}
        >
          <Button
            onClick={this.handleClose}
          >
            <KeyboardReturn />
          </Button>
          <div className={classes.modalButtonImg} >
            {buttonBack()}
            <div className={classes.imgContainer} >
              <div/>
              <img
                className={classes.imgModal}
                src={cache[newTab[value].path].default}
                alt=""
              />
              <div/>
            </div>
            {buttonForward()}
          </div>
        </Dialog>

      </div>
    )
  }

  decrease = () => {
    const { value, newTab } = this.state
    let copyValue = value
    if (value === 0) {
      copyValue = newTab.length
    }
    this.setState({value: (copyValue - 1) % newTab.length})
  }

  increase = () => {
    this.setState({value: (this.state.value + 1) % this.state.newTab.length})
  }

  handleOpen = () => {
    this.setState({isOpen: true})
  }

  handleClose = () => {
    this.setState({isOpen: false})
  }

  filterByTag = (e) => {
    const tag = e.currentTarget.value
    const newTab = []
    if (tag === 'all') {
      this.setState({value: 0, newTab: Object.values(tab)})
    } else {
      Object.values(tab).map(el => {
        if (tag === el.tag) {
          newTab.push(el)
        }
        return null
      })
      this.setState({value: 0, newTab: newTab})
    }
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '2%',
    width: '70%'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonNav: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    width: "30px",
    margin: "10px 0px 10px 0px",
  },
  card: {
    width: "300px",
    margin: '2%',
  },
  img: {
    objectFit: 'cover',
    height: '200px',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  modalButtonImg: {
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '90%',
  },
  imgModal: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
})

const styledImages = withStyles(styles)(Images);
export { styledImages as Images }
