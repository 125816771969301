import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { news_paper } from '../prog/newspaper/sketch';

class Newspaper extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div id="paper" className={classes.root}>
                    <div className={classes.info}>
                        <p>
                            enter: fullscreen<br />
                            left arrow: previous filter<br />
                            right arrow: next filter<br />
                        </p>
                    </div>
                    {news_paper()}
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: 650
    },
    info: {
        position: 'absolute',
        left: 0,
        paddingLeft: 20,
        paddingRight: 20,
    }
});

const styledNewspaper = withStyles(styles)(Newspaper);
export { styledNewspaper as Newspaper };