import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  HashRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Home, Images, Texts, Novel, Gif, Program } from "./components";

class App extends Component {
  render() {
    const { classes } = this.props
    return (
      <div>
        <HashRouter>
          <div>
            <nav>
              <ul className={classes.root}>
                <li>
                  <Link className={classes.link} to="/">Home</Link>
                </li>
                <li>
                  <Link className={classes.link} to="/image">Images</Link>
                </li>
                <li>
                  <Link className={classes.link} to="/text">Textes</Link>
                </li>
                <li>
                  <Link className={classes.link} to="/novel">Nouvelle</Link>
                </li>
                <li>
                  <Link className={classes.link} to="/animation">Animations</Link>
                </li>
                <li>
                  <Link className={classes.link} to="/program">Programmes</Link>
                </li>
              </ul>
            </nav>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/image">
                <Images />
              </Route>
              <Route path="/text">
                <Texts />
              </Route>
              <Route path="/novel">
                <Novel />
              </Route>
              <Route path="/animation">
                <Gif />
              </Route>
              <Route path="/program">
                <Program />
              </Route>
            </Switch>
          </div>
        </HashRouter>
        <footer className={classes.footer}>
          <a rel="license" href="http://creativecommons.org/licenses/by-nc/2.0/fr/"><img alt="Licence Creative Commons" style={{ borderWidth: 0 }} src="https://i.creativecommons.org/l/by-nc/2.0/fr/88x31.png" /></a> Ce(tte) œuvre est mise à disposition selon les termes de la < a rel = "license" href = "http://creativecommons.org/licenses/by-nc/2.0/fr/" > Licence Creative Commons Attribution - Pas d’Utilisation Commerciale 2.0 France</a >.
        </footer>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
  },
  link: {
    "&:hover": {
      background: '#555555',
      color: 'white'
    },
    color: '#555555',
    textDecoration: 'none',
    padding: 10,
    transitionDuration: '0.5s'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    padding: 10
  }
});

const withStylesApp = withStyles(styles)(App);
export { withStylesApp as App };